import React from "react";
import { Link } from "react-router-dom";
function MIMS(props) {
  return (
    <div>
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h3>ABOUT MIMS</h3>
        </div>
        <div className="row">
          <p className="mims-font-size">Headquartered in Singapore, the MIMS Group is supported by a team of 1,100 professionals spread across 36 global offices spanning 18 countries and regions. We are the leading multichannel provider of trusted, quality medical information, medical education and knowledge services connecting healthcare communities. We provide tailored solutions to leading pharmaceutical companies and established healthcare institutions across the world.</p>
        </div>
        <div className="section-title text-center">
          <h3>LOCALLY-ACCREDITED DRUG INFORMATION FOR LOCAL PRACTICE NEEDS</h3>
        </div>
        <div className="row">
        <div className="col-xs-2 col-md-2">
            {" "}
            <img src="img/mims.png" className="img-responsive" alt="" />{" "}
          </div>
          <p className="mims-font-size">Ensure maximum clinical relevance in local practice settings by delivering a unique mix of drug prescribing information approved by local government health authorities as well as proprietary generic drug monographs compiled by the MIMS Editorial Team of physicians and pharmacists.</p>
        </div>
        <div className="section-title text-center">
          <h3>ASIA-PACIFIC'S MOST TRUSTED DRUG REFERENCE RESOURCE</h3>
        </div>
        <div className="row">
          <p className="mims-font-size">Renowned as the hallmark of drug information excellence for over 60 years in Asia-Pacific, MIMS serves medical professionals across countries such as; Australia, New Zealand, China, Malaysia, Philippines, Hong Kong, Singapore, India, South Korea, Vietnam, Taiwan and Thailand. Over the years, physicians have consistently cited MIMS as their No. 1 choice of drug reference. Today, nearly 2.5 million healthcare professionals rely on MIMS for timely access to authoritative drug information, medical news and continuing education resources.</p>
        </div>
        <div className="section-title text-center">
          <h3>24/7 REAL-TIME ACCESS ACROSS MULTIPLE PLATFORMS AND CHANNELS</h3>
        </div>
        <div className="row">
          <p className="mims-font-size">Designed as an essential information tool in a physician's daily workflow, MIMS has grown from a desktop drug directory delivering up-to-date and complete drug information, to a new-generation drug and medical reference tool available at every point of care, encompassing print publications, online drug and medical resource portal - MIMS.com, integrated drug knowledge bases within hospital information systems, mobile applications, and standalone reference platforms.
          In line with the MIMS philosophy to deliver accessible, relevant and responsive medical and drug information to help the medical community improve therapeutic and patient management outcomes, MIMS continually researches and implements relevant clinical practice tools aligned with physicians's clinical habits and needs to deliver pertinent and crucial clinical information across multiple platforms at every point of care.</p>
          <br/>
          <p>For more information in Australia, please visit us at <br/>
          <a href="https://www.mims.com.au/" 
              target="_blank"
              rel="noopener noreferrer">MIMS AUSTRALIA
          </a>
          </p>
          <p>For more information across Asia Pacific, please visit us at <br/>
          <a href="https://corporate.mims.com/"
          target="_blank"
          rel="noopener noreferrer"> MIMS ASIA PACIFIC</a>
          </p>
        </div>
      </div>

    </div>
    <div id="footer-mims">
        <div className="container text-center">
          <div class="col-lg-2 col-lg-offset-2 col-md-2 col-sm-10">  
          <Link to="/mims">About MIMS</Link>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-10">       
          <Link to="/privacy">Privacy</Link>
          </div> 
          <div class="col-lg-2 col-md-2 col-sm-10">
          &copy; 2024 eHealthWise{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MIMS;
